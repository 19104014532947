import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import logo from "../../assets/logo-f.svg";
import background from "../../assets/background.png";

import classes from "./Header.module.css";

const Header = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResizeWindow = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, [width]);

  const location = useLocation();
  const tab = location.pathname;
  const visible = location.pathname === "/";

  const navigate = useNavigate();

  return (
    <header>
      <section className={classes.headerContainer}>
        <img
          src={logo}
          alt="Logo"
          className={visible ? classes.logo : classes.logo2}
        />
        <img
          src={background}
          alt="Background"
          className={visible ? classes.background : classes.backgroundHidden}
        />
        <article
          className={visible ? classes.navContainer : classes.navContainer2}
        >
          <button className={classes.menuButton} onClick={() => navigate("/")}>
            Home
          </button>
          <button
            className={classes.menuButton}
            onClick={() => navigate("/info")}
            style={{ background: tab === "/info" ? "blue" : "none" }}
          >
            Info
          </button>
          <button
            className={classes.menuButton}
            onClick={() => navigate("/products")}
            style={{ background: tab === "/products" ? "blue" : "none" }}
          >
            Products
          </button>
          <button
            className={classes.menuButton}
            onClick={() => navigate("/contact")}
            style={{ background: tab === "/contact" ? "blue" : "none" }}
          >
            Contact
          </button>
          <button
            className={classes.menuButton}
            onClick={() => navigate("/users")}
            style={{ background: tab === "/users" ? "blue" : "none" }}
          >
            Users
          </button>
        </article>
      </section>
    </header>
  );
};

export default Header;
