import React from "react";
import classes from "./Container.module.css";

interface ListProps {
  icon: React.ReactElement;
  titleText: string;
  list: Array<string | React.ReactElement>;
}

const List: React.FC<ListProps> = ({ icon, titleText, list }) => {
  return (
    <section className={classes.card}>
      <article className={classes.cardTitle}>
        {React.cloneElement(icon, { className: classes.icon })}
        <span className={classes.titleText}>{titleText}</span>
      </article>
      <ul className={classes.serviceList}>
        {list.map((i, index) => {
          return <li key={titleText + index}>{i}</li>;
        })}
      </ul>
    </section>
  );
};

export default List;
