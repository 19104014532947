import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Info from "./pages/Info";
import Products from "./pages/Products";
import Contact from "./pages/Contact";
import Profile from "./pages/Profile";
import Error from "./pages/Error";

const App: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/info" element={<Info />} />
      <Route path="/products" element={<Products />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/users" element={<Error error="u" />} />

      <Route path="/dr_dilip_chate" element={<Profile />} />
      <Route path="/dr-dilip-chate" element={<Profile />} />
      <Route path="/dilip_chate" element={<Profile />} />
      <Route path="/dilip-chate" element={<Profile />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/research" element={<Profile />} />
      <Route path="/publications" element={<Profile />} />

      <Route path="*" element={<Error error="" />} />
    </Routes>
  );
};

export default App;
