import React from "react";

import { useState, useEffect } from "react";

import { CiMail } from "react-icons/ci";
import { BsTelephone } from "react-icons/bs";

const Footer: React.FC = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 700;

  useEffect(() => {
    const handleResizeWindow = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, [width]);

  return (
    <div
      style={{
        fontSize: width > breakpoint ? "medium" : "small",
        textAlign: "center",
        backgroundColor: "#24272E",
        borderTop: "3px solid rgba(26, 21, 21, 0.744)",
        outline: "1px solid rgb(20, 16, 16, 0.7)",
        padding: "6px",
      }}
    >
      &#169;&nbsp; SMIT Enviro Solutions
      <sup>
        <span style={{ fontSize: "x-small" }}>TM</span>
      </sup>
      &nbsp;&nbsp;&#124;&nbsp;&nbsp;
      <CiMail style={{ margin: "5px 5px 0px" }} />{" "}
      <a
        href="mailto:admin@smitenvirosolutions.in"
        style={{
          textUnderlineOffset: "3px",
        }}
      >
        admin@smitenvirosolutions.in
      </a>
      &nbsp;&nbsp;&#124;&nbsp;&nbsp;
      <BsTelephone style={{ margin: "5px 5px 0px" }} />{" "}
      &nbsp;&#43;91&#45;9022828488
    </div>
  );
};

export default Footer;
